.container {
  padding: 0 2rem;
  height: 6rem;
  background: #FFFFFF;
  border: .25rem solid #E8EBF3;
  border-radius: .5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all .2s;

  span {
    font-size: 2rem;
    font-weight: 600;
    color: var(--label-blue);
  }

  svg {
    font-size: 2rem;
    color: var(--label-blue)
  }
}

.pressed {
  &:not([disabled]) {
    border-color: var(--third-blue);
    box-shadow: .5rem .5rem 1rem rgba(62, 84, 227, 0.1);

    span {
      color: var(--third-blue);
    }
  }
}

.checkbox{
  width: 48px;
  height: 48px;
  border: .25rem solid var(--third-blue);
  border-radius: .25rem;

  &-checked{
    background: var(--third-blue);
    border-radius: .25rem;
    display: flex;
    justify-content: center;
    align-items: center;

    svg{
      color: #fff;
    }
  }
}

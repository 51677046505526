.main{
  height: 100vh;
}

.background{
  background-image: linear-gradient(to left, var(--dark-blue), var(--medium-blue));
  width: 100%;
  height: 100vh;

  &-logo{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
}
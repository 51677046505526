.button{
  background-color: var(--third-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 6rem;
  width: 100%;
  border-radius: .25rem;
  border: none;
  transition: all .2s;
  
  svg{
    color: #00FFA3;
    font-size: 3rem;
    margin-right: 1rem;
  }

  span{
    color: #fff;
    font-size: 2rem;
    font-weight: 600;
  }

  &:active{
    background-color: var(--first-blue);

    svg{
      color: var(--third-blue);
    }
  }
}
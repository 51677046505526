.container{
  & > div:not(:last-of-type){
    margin-bottom: 1.5rem;
  }

  &-top{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img{
      margin-bottom: 3rem;
    }

    h1{
      font-size: 3rem;
      color: var(--label-blue);
    }
  }
}

.button-container{
  margin: 5rem 0;
}
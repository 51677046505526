.header, .options-header{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to left, var(--dark-blue), var(--medium-blue));
  border-radius: 0 0 111px 111px;
  box-shadow: 12px 12px 40px rgba(55, 72, 183, 0.2);
  border-bottom: 7px solid var(--light-blue);
}

.header{
  z-index: 20;
  height: 210px;

  &-container{
    height: fit-content;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  &-title{
    color: var(--medium-gray);
    font-size: 2rem;
    line-height: 40px;
    font-weight: 600;
  }

  &-back-button{
    background-color: #0000;
    border: none;
    width: 75px;

    svg{
      color: var(--light-gray);
      font-size: 3.25rem;
    }
  }

  &-logo {
    width: 80px;
  }
}

.call-button {
  background-color: #0000;
  border: 1px solid var(--third-blue);
  border-radius: .25rem;
  color: var(--first-blue);
  font-size: 2.5rem;
  display: flex;
  align-items: center;
  padding: 1rem;
}

.options-header {
  flex-direction: column;
  height: 450px;
  padding: 2rem 5rem;

  &-logo {
    width: 200px;
  }

  &-title {
    margin-top: 10rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 2rem;
    font-weight: 700;

    p:first-of-type {
      text-transform: uppercase;
      color: #FFFFFF;
    }

    p:not(:last-of-type){
      margin-bottom: 1rem;
    }

    p:last-of-type {
      color: #CADAF9;
    }
  }
}


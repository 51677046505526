.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.animation {
  position: relative;
  margin: auto;
  height: 16rem;

  @keyframes expand {
    0%,
    100% {
      transform: scale(0.5, 0.5);
    }

    25%,
    75% {
      transform: scale(0.75, 0.75);
    }

    50% {
      transform: scale(1, 1);
    }
  }

  &-in,
  &-out,
  &-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-in {
    position: relative;
    border-radius: 50%;
    width: 10rem;
    height: 10rem;
    background: #cee1f2;
    animation: expand 3s infinite linear;
  }

  &-out {
    border-radius: 50%;
    width: 16rem;
    height: 16rem;
    background-color: rgba(226, 240, 252, 0.75);
    animation: expand 3s infinite linear;
  }

  &-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0.5rem 0.5rem 0.75rem rgba(55, 72, 183, 0.2);
    color: #00ffe0;
    font-size: 3rem;
    background-color: var(--third-blue);
    border: 0.25rem solid #fff;
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
  }
}

.infos {
  &-name {
    text-align: center;
    font-weight: 700;
    font-size: 2rem;
    color: var(--label-blue);
    margin-top: 2%;
  }

  &-items {
    display: flex;
    justify-content: center;
    margin-top: 2%;
  }

  &-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 700;

    &:first-of-type {
      margin-right: 2rem;
    }

    &-label {
      font-size: 1.5rem;
      color: var(--medium-gray);
    }

    &-value {
      font-size: 2rem;
      color: var(--third-blue);
    }
  }
}

.end-call {
  margin-top: 3%;

  &-button {
    background-color: var(--pink);
    border: 0.125rem solid var(--pink);
    border-radius: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6rem;
    height: 6rem;
    transition: all 0.2s;

    & > img {
      width: 3rem;
      height: 1.5rem;
    }

    &:active {
      background-color: #0000;
    }
  }
}

.container{
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  &-blur {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    filter: blur(40px);
  }
}

.track {
  position: relative;
  width: 80%;
  margin: auto;
  display: grid;
  height: 9px;
  border: .0625rem solid var(--medium-gray);
  border-radius: 100px;
}

.part-undone, .part-done{
  position: relative;

  &:first-of-type {
    border-radius: 100px 0 0 100px;
  }
  
  &:last-of-type {
    border-radius: 0 100px 100px 0;
  }
}

.part{
  position: relative;
  border: .0625rem solid var(--medium-gray);

  &:not(:last-of-type){
    border-right: none;
  }

  &-done{
    background-color: var(--third-blue);
  } 
}

.checkpoint{
  position: absolute;
  right: 0;
  top: -150%;

  &-bullet{
    box-sizing: content-box;
    position: relative;
    background-color: var(--third-blue);
    border: .125rem solid var(--first-blue);
    border-radius: 8px;
    width: 31px;
    height: 31px;
    display: flex;
    align-items: center;
    justify-content: center;

    &-undone{
      box-sizing: content-box;
      border: .125rem solid #0000;
      border-radius: 8px;
      width: 31px;
      height: 31px;
    }

    & > div{
      background-color: #fff;
      width: 13px;
      height: 13px;
      border-radius: 50%;
    }
  }

  &-label{
    color: var(--label-blue);
    position: absolute;
    transform: translateX(-30%);
    font-size: 1.25rem;
    font-weight: 700;
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Quicksand, Helvetica, Arial, sans-serif;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

:root{
  --first-blue: #00C2FF;
  --second-blue: #1B1C34;
  --second-blue-500: #1F3464;
  --third-blue: #3E54E3;
  --label-blue: #556A94;
  --pink: #F14471;

  --dark-blue: #0C173E;
  --medium-blue: #2F4B84;
  --light-blue: #05BFFA;
  --medium-gray: #959FB3;
  --light-gray: #E8EBF3;
}

.Toastify__toast-container {
  width: 80% !important;
  margin: auto !important;
}

.Toastify__toast{
  height: 120px !important;
  min-height: 0px !important;
  max-height: 100% !important;
}

.Toastify__toast-body{
  font-size: 2rem;
  font-weight: 700;
}

.Toastify__toast-icon {
  margin-inline-end: 25px !important;
  width: 40px !important;
}
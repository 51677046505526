.one-column-grid {
  width: 100%;
  margin: auto;
  display: grid;
  gap: 2.5rem;
  grid-template-columns: repeat(1, 1fr);

  &>button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.horizontal-padding {
  padding: 2rem;
}

.cards-margin-bottom {
  &>div {
    margin-bottom: 2rem;
  }
}
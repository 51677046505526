.container{
  padding: 0 2rem;
}

.chat-container{
  margin: 1rem 0;
}

.content {
  margin: auto;
  // position: relative;
  overflow-y: auto;

  &-top{
    width: 100%;
  }

  &-bottom{
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.bottom {
  position: absolute;
  bottom: 0;
  width: 100%;

  &-blur{
    position: absolute; 
    left: 0;
    bottom: 0;
    z-index: -10;
    width: 200px;
    height: 210px;
    background: rgba(241, 68, 113, 0.5);
    border: .75rem solid var(--pink);
    filter: blur(120px);
    border-radius: 111px;
  }

  &-squares{
    position: absolute;
    z-index: 10;
    left: 50%;
    bottom: 0;
    width: fit-content;
    transform: translateX(-50%);
    height: 10px;
    overflow: hidden;  
    display: flex;
  }

  &-square{
    width: 164.8px;
    height: 77px;
    border-radius: 12px;
  
    &:not(:last-of-type) {
      margin-right: .5rem;
    }
  }
}

.blur {
  position: absolute;
  z-index: -10;
  width: 286px;
  height: 284px;
  right: 0;
  top: 50%;

  background: rgba(84, 174, 223, 0.5);
  filter: blur(145px);
  border-radius: 80px;
}
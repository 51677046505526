.container{
  position: relative;
  height: 100%;
}

.horizontal-padding {
  padding: 0 2rem;
}

.contacts{
  &-info{
    padding: 2rem 0;
    border-top: .125rem solid var(--light-gray);
    display: flex;
    justify-content: center;
    
    &-item{
      &:first-of-type{
        margin-right: 3rem;
      }

      text-align: center;
      font-weight: 700;
    }

    &-label{
      font-size: 1.5rem;
      color: var(--medium-gray);
    }

    &-value {
      font-size: 2rem;
      color: var(--third-blue);
    }
  }

  &-title{
    display: flex;

    div {
      margin-right: 1rem;
      border: .125rem solid var(--light-gray);
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;

      svg {
        font-size: 1.75rem;
        color: var(--first-blue);
      }
    }
  }
}

.one-column-grid, .one-column-grid-tower{
  width: 100%;
    margin: auto;
    display: grid;
    gap: 2.5rem;
    grid-template-columns: repeat(1, 1fr);
}

.one-column-grid{
  & > button{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &-tower{
    & > button{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.cards-margin-bottom{
  & > div{
    margin-bottom: 2rem;
  }
}

.condominium-key-label{
  font-size: 1.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  padding-left: 2.5rem;

  svg{
    margin-right: 1rem;
    font-size: 2.5rem;
  }
}

.two-columns-grid{
  margin: auto;
  display: grid;
  gap: 2.5rem;
  grid-template-columns: repeat(2, 1fr);
  
  & > button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
  }
}

.apartment-key {
  font-size: 3rem;
}

.tower-key{
  font-size: 6rem;
}
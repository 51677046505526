.container {
  background-color: #fff;
  position: relative;
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.75rem;
  border-left: 0.125rem solid var(--third-blue);
  box-shadow: 0.75rem 0.75rem 2rem rgba(62, 84, 227, 0.1);

  &-name {
    margin-bottom: 0.25rem;
    font-weight: 700;
    font-size: 2rem;
    color: var(--label-blue);
  }

  &-permission {
    margin-bottom: 2rem;
    font-weight: 600;
    font-size: 1.25rem;
    color: var(--label-blue);
  }

  &-buttons {
    width: 70%;
    position: absolute;
    display: flex;
    justify-content: space-evenly;
    bottom: -38%;
    left: 50%;
    transform: translate(-50%, -50%);

    &-messages {
      background-color: #fff;
      height: 4.75rem;
      width: 4.75rem;
      line-height: 2.4;
      color: var(--third-blue);
      border: 0.25rem var(--light-gray) solid;
      border-radius: 50%;
      font-size: 2rem;
      transition: all 0.2s;

      &:active {
        border-color: var(--third-blue);
      }
    }
  }
}

.modal{
  background-color: #1B1C3477;
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &-content{
    padding: 3rem;
    position: relative;
    background-color: #fff;
    border: .375rem var(--third-blue) solid;
    border-radius: 2rem;
  }

  &-title{
    font-weight: 700;
    font-size: 2rem;
    color: var(--third-blue);
    margin-bottom: 1rem;
  }

  &-message{
    font-size: 1.5rem;
    color: var(--medium-gray);
    margin-bottom: 3rem;
  }

  &-buttons{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
  }

  &-confirm, &-cancel{
    padding: 2rem;
    width: 100%;
    border-radius: .25rem;
    font-size: 1.5rem;
    font-weight: 600;
  }

  &-confirm{
    background-color: var(--third-blue);
    color: #fff;
    border: none;
  }

  &-cancel{
    background-color: #fff;
    color: var(--label-blue);
    border: var(--third-blue) solid .25rem;
  }
}
.container {
  padding: 0 2rem;
  height: 6rem;
  background: #FFFFFF;
  border: .25rem solid var(--light-gray);
  border-radius: .5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all .2s;

  span{
    font-size: 2rem;
    font-weight: 600;
    color: var(--label-blue);
  }

  svg {
    font-size: 2rem;
    color: var(--label-blue)
  }
}

.pressed {
  border-color: var(--third-blue);
  box-shadow: .5rem .5rem 1rem rgba(62, 84, 227, 0.1);

  span {
    color: var(--third-blue);
  }
}

.modal{
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #1B1C3477;
  display: flex;
  align-items: flex-end;

  &-content{
    max-height: 60vh;
    padding: 4rem 3rem;
    border-radius: 4rem 4rem 0px 0px;
    border-top: .75rem solid var(--third-blue);
    position: absolute;
    width: 100%;
    z-index: 50;
    background-color: #fff;

    &-items{
      max-height: calc(60vh - 9.25rem);
      overflow-y: auto;
    }

    &-item{
      margin-top: 1.5rem;
      padding: 1rem;
      color: var(--label-blue);
      font-weight: 600;
      border: .25rem solid #E8EBF3;
      display: flex;
      align-items: center;
      border-radius: .625rem;

      &:last-of-type {
        margin-bottom: 1.5rem;
      }

      &:active {
        border-color: var(--third-blue);

        svg {
          color: var(--third-blue);
        }
      }

      svg{
        font-size: 2rem;
        margin-right: 1rem;
      }

      span{
        font-size: 1.5rem;
      }
    }
  }
}
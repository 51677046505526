.button{
  background-color: #fff;
  border-radius: 10px;
  border: .3125rem solid var(--light-gray);
  color: var(--label-blue);
  font-weight: 700;
  transition: all .2s;
  
  &:active{
    border-color: var(--third-blue);
    color: var(--third-blue);
    box-shadow: .5rem .5rem 1.5rem rgba(62, 84, 227, 0.25);
  }
}
.button{
  text-align: center;
  border: none;

  div{
    display: flex;
    align-items: center;
    justify-content: center;

    svg{
      margin-right: 1.5rem;
    }
  }
}

.lg, .sm{
  font-weight: 600;
}

.sm{
  border-radius: 3.5rem;
  width: 250px;
  font-size: 1.5rem;

  div{
    border-radius: 3.5rem;
  }

  svg{
    font-size: 2rem;
  }
}

.lg{
  border-radius: 6rem;
  width: 500px;
  font-size: 3rem;

  div {
    border-radius: 3.75rem;
  }

  svg {
    font-size: 4.25rem;
  }
}

.second-blue, .third-blue{
  &.lg{
    padding: 2rem;
  }
  &.sm {
    padding: 1.25rem;
  }
  box-shadow: .25rem .25rem 1rem rgba(36, 68, 235, 0.2);

  span{
    color: #fff;
  }

  svg{
    color: var(--first-blue);
  }

  &:active{
    background-color: var(--first-blue);

    svg {
      color: #fff;
    }
  }
}

.outlined{
  padding: .25rem;
  background-image: linear-gradient(to bottom, var(--second-blue-500), var(--light-blue));
  transition: all .2s;

  div{
    padding: 1.75rem;
    background-color: #fff;
    color: var(--second-blue-500);
  }

  svg{
    color: var(--label-blue);
  }

  &:active{
    background-image: none;
    background-color: var(--second-blue-500);

    div{
      background-color: #0000;
      color: #fff;
    }
  }
}

.second-blue{
  background-color: var(--second-blue-500);
}

.third-blue {
  background-color: var(--third-blue);
}
.bottom-squares, .top-squares {
  position: absolute;
  z-index: 10;
  left: 50%;
  width: fit-content;
  transform: translateX(-50%);
  overflow: hidden;
  display: flex;
}

.bottom-squares{
  bottom: 0;
}

.top-squares {
  top: 0;
}

.square {
  transform: skew(-50deg);
  width: 165px;
  height: 10px;
  border-radius: .75rem;

  &:not(:last-of-type) {
    margin-right: .5rem;
  }
}
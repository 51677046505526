.container {
  border: .25rem solid var(--light-gray);
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 10px;

  &:focus {
    outline: none;
  }

  svg {
    margin-right: 1rem;
    font-size: 1.5rem;
    color: var(--medium-gray);
  }

  input {
    border: none;
    font-size: 1.5rem;
    font-weight: 600;

    &::placeholder{
      color: var(--medium-gray);
      font-weight: 600;
    }

    &:focus{
      outline: none;
    }
  }
}
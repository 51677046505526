.container{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 12rem 0;

  button:first-of-type{
    margin-bottom: 3rem;
  }
}